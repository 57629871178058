import { Dropdown, Modal, Toast } from "bootstrap";
import { ajax as htmxAjax } from "htmx.org";
import TomSelect from "tom-select";
import {Sortable, Swap} from "sortablejs";
import ClipboardJS from "clipboard";

function backend() {
  /**
   * Einige Hilfsfunktionen
   */
  let showToasts = function () {
    $messages = document.getElementById("backend-messages");

    if ($messages === undefined || $messages === null) return;

    $messages.querySelectorAll(".toast").forEach((el) => {
      const toast = new Toast(el);
      toast.show();
    });
  };

  let getCookie = function (name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  let getScript = function (source, callback) {
    var script = document.createElement("script");
    var prior = document.getElementsByTagName("script")[0];
    script.async = 1;

    script.onload = script.onreadystatechange = function (_, isAbort) {
      if (
        isAbort ||
        !script.readyState ||
        /loaded|complete/.test(script.readyState)
      ) {
        script.onload = script.onreadystatechange = null;
        script = undefined;

        if (!isAbort && callback) setTimeout(callback, 0);
      }
    };

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
  };

  // Body verfügbar machen
  let $body = document.querySelector("body");

  // CSRF-Token setzen
  $body.addEventListener("htmx:configRequest", (evt) => {
    if (process.env.NODE_ENV === 'development') {
      evt.detail.headers["X-CSRFToken"] = getCookie("csrftoken");
    } else {
      evt.detail.headers["X-CSRFToken"] = getCookie("backendcsrftoken");
    }
  });

  /**
   * Toast initialisieren
   */
  showToasts();
  $body.addEventListener("htmx:afterSwap", () => {
    showToasts();
  });

  /**
   * Select-Boxen initialisieren
   */
  $body.querySelectorAll(".tom-select").forEach((el) => {
    new TomSelect(el, {});
  });
  $body.addEventListener("refreshTomSelect", (e) => {
    $body
      .querySelectorAll("select.tom-select:not(.tomselected)")
      .forEach((el) => {
        new TomSelect(el, {});
      });
  });

  /**
   * Sortierbares initialisieren
   */
  $body.querySelectorAll(".sortable").forEach((el) => {
    Sortable.create(el, {
      handle: el.dataset.dragHandle,
    });
  });

  Sortable.mount(new Swap());
  $body.querySelectorAll(".swappable").forEach((el) => {
    Sortable.create(el, {
      swap: true,
      swapClass: "swap-highlight",
      draggable: ".draggable-element",
      filter: ".not-draggable",
      group: el.dataset.swapGroup ?? null,
      handle: el.dataset.dragHandle ?? null,
    });
  });

  let toggleDisabledState = function (elements, toggle) {
    elements.forEach((el) => {
      if (toggle === undefined) {
        el.disabled = !el.disabled;
        return;
      }
      el.disabled = toggle;
    });
  };

  $body
    .querySelectorAll("input[data-input-dependency-toggle]")
    .forEach((toggle) => {
      const $group = toggle.closest("div[data-input-dependency-group]");

      toggle.addEventListener("input", (ev) => {
        $body
          .querySelectorAll("div[data-input-dependency-group]")
          .forEach((el) => {
            toggleDisabledState(
              el.querySelectorAll(
                "input:not(input[data-input-dependency-toggle]), select"
              ),
              true
            );
          });
        toggleDisabledState(
          $group.querySelectorAll(
            "input:not(input[data-input-dependency-toggle]), select"
          ),
          !toggle.checked
        );
      });
      toggleDisabledState(
        $group.querySelectorAll(
          "input:not(input[data-input-dependency-toggle]), select"
        ),
        !toggle.checked
      );
    });

  /**
   * Zwischenablage
   */
  new ClipboardJS(".btn-clipboard");

  /**
   * Grundeinstellungen: Datenschutz
   */
  if ($body.classList.contains("main-privacy")) {
    let $dpraSelector = document.querySelector(
      "select[name=dp_regulatory_authorities]"
    );
    $dpraSelector.addEventListener("change", (ev) => {
      document.querySelector(
        "textarea[name=dp_regulatory_authority]"
      ).innerHTML =
        $dpraSelector.options[ev.target.selectedIndex].dataset.address;
    });
  }

  /**
   * Funktionsumfang: Allgemeines
   */
  if ($body.classList.contains("functions-general")) {
    let $map = document.getElementById("map");
    let $coordinateX = document.getElementById("f_coordinate_x");
    let $coordinateY = document.getElementById("f_coordinate_y");

    let map;
    let marker;

    let leafletCSS = document.createElement("link");
    leafletCSS.setAttribute("rel", "stylesheet");
    leafletCSS.setAttribute("type", "text/css");
    leafletCSS.setAttribute("href", "/static/js/vendor/leaflet/leaflet.css");
    document.head.appendChild(leafletCSS);

    getScript("/static/js/vendor/leaflet/leaflet.js", () => {
      L.Icon.Default.imagePath = "/static/js/vendor/leaflet/images/";

      $map.style.height = "350px";

      map = L.map("map").setView([$coordinateX.value, $coordinateY.value], 15);
      L.tileLayer("https://tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png", {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.de/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
        maxZoom: 15,
      }).addTo(map);

      marker = L.marker([$coordinateX.value, $coordinateY.value], {
        draggable: true,
        autoPan: true,
      }).addTo(map);

      marker.on("dragend", (e) => {
        var marker = e.target;
        var position = marker.getLatLng();
        document.getElementById("f_coordinate_x").value = position.lat;
        document.getElementById("f_coordinate_y").value = position.lng;

        document
          .getElementById("section-map")
          .dispatchEvent(new Event("mapupdate"));
      });
    });

    $body.addEventListener("htmx:afterSwap", (evt) => {
      if (evt.target.id == "section-map") {
        let coordinateX = document.getElementById("f_coordinate_x").value;
        let coordinateY = document.getElementById("f_coordinate_y").value;
        map.setView([coordinateX, coordinateY], 15);
        marker.setLatLng([coordinateX, coordinateY]);
      }
    });
  }

  /**
   * Medienlisten: Selektoren-Modals initialisieren
   */
  $body.addEventListener("openSelectorModal", (e) => {
    const $selectorModal = $body
      .querySelector("#modals")
      .querySelector(".modal");
    if ($selectorModal === undefined || $selectorModal === null) return;

    new TomSelect($selectorModal.querySelector(".tom-select"), {});

    const selectorModal = new Modal($selectorModal);
    selectorModal.show();

    $selectorModal.addEventListener("submit", (e) => {
      selectorModal.hide();
    });

    $selectorModal.addEventListener("shown.bs.hidden", (e) => {
      selectorModal.dispose();
    });
  });

  /**
   * Integration des Confirmation-Modals
   */
  let $confirmModal = document.querySelector("#confirmModal");
  if ($confirmModal !== undefined && $confirmModal !== null) {
    const confirmModal = new Modal($confirmModal);

    $body.addEventListener("click", (ev) => {
      let $el = ev.target.closest("[data-confirm-modal]");
      if ($el === null || $el === undefined) return;

      ev.preventDefault();
      confirmModal._dialog.querySelector("h3").innerHTML =
        $el.dataset.confirmTitle;
      confirmModal._dialog.querySelector("span").innerHTML =
        $el.dataset.confirmText;
      confirmModal._dialog
        .querySelector("button[data-confirm-submit]")
        .addEventListener("click", (ev) => {
          ev.preventDefault();
          $el.dispatchEvent(new Event($el.getAttribute("hx-trigger")));
          confirmModal.hide();
        });
      confirmModal.show();
    });

    /**
     * Integration des FileSelektor-Modals
     */
    let selectFileCallback = function (ev) {
      let target = $body.querySelector("div[data-file-select-fields]");
      if (target === null || target === undefined) return;

      if (ev.detail !== undefined) {
        target.querySelector("input[name=image]").value = ev.detail.fileName;
      } else {
        target.querySelector("input[name=image]").value = "";
      }

      $body.removeEventListener(
        "bvseopac:file-selected",
        selectFileCallback,
        false
      );
      target.dispatchEvent(new Event("bvseopac:save-files"));
    };

    let $fileSelectButton = document.querySelector("button[data-select-file]");
    let $fileUnselectButton = document.querySelector(
      "button[data-unselect-file]"
    );

    if ($fileSelectButton !== undefined && $fileSelectButton !== null) {
      $fileSelectButton.addEventListener("click", (ev) => {
        ev.preventDefault();
        $body.addEventListener(
          "bvseopac:file-selected",
          selectFileCallback,
          false
        );

        $body.dispatchEvent(
          new CustomEvent("bvseopac:open-files-selector", {
            detail: {
              filter: "image",
            },
          })
        );
      });
    }

    if ($fileUnselectButton !== undefined && $fileUnselectButton !== null) {
      $fileUnselectButton.addEventListener("click", (ev) => {
        ev.preventDefault();
        selectFileCallback("");
      });
    }

    let $fileSelectModal = document.querySelector("#fileSelectModal");
    if ($fileSelectModal !== undefined && $fileSelectModal !== null) {
      const fileSelectModal = new Modal($fileSelectModal);

      $body.addEventListener("bvseopac:open-files-selector", (ev) => {
        let url = $fileSelectModal.dataset.endpoint;
        url = url + "?select=1";
        if (ev.detail.filter !== undefined && ev.detail.filter !== null) {
          url = url + "&filter=" + ev.detail.filter;
        }

        htmxAjax("GET", url, {
          target: "#fileSelectModal .modal-body",
        });
        fileSelectModal.show();
      });

      $fileSelectModal.addEventListener("click", (ev) => {
        let $el = ev.target.closest("[data-select-file]");
        if ($el === null || $el === undefined) return;

        if ($el.dataset.file === undefined || $el.dataset.file === null) {
          fileSelectModal.hide();
          return;
        }

        $body.dispatchEvent(
          new CustomEvent("bvseopac:file-selected", {
            detail: {
              fileName: $el.dataset.file,
              alt: $el.dataset.fileAlt,
              figcaption: $el.dataset.fileFigcaption,
            },
          })
        );
        fileSelectModal.hide();
      });
    }
  }
}

if (document.readyState == "loading") {
  document.addEventListener("DOMContentLoaded", backend);
} else {
  backend();
}
